export const QUERY_KEYS = {
  STAMPS: "stamps",
  NEW_QR_CODE: "NEW_QR_CODE",
  ARTISTS: "ARTISTS",
  PRIVATE_KEY: "PRIVATE_KEY",
  SUBMISSIONS: "SUBMISSIONS",
  SUBMISSION: "SUBMISSION",
  WHEEL_ENTRIES: "WHEEL_ENTRIES",
  SHOW_INTRO: "SHOW_INTRO",
};
